<template>
	<div class="content-wrap humiture">
		<Spin fix v-if="loading"></Spin>

		<!--搜索框-->
		<CtmsSearchBox @onSearch="searchHandle" @onReset="reset">
			<CtmsSearchBoxItem :span="1" label="名称" placement="top">
				<Input v-model="searchParams.name" placeholder="请输入名称" />
				<!--<DatePicker
          type="datetime"
          placeholder="请选择创建日期"
          :transfer="true"
          style="width:100%"
          format="yyyy-MM-dd"
          :value="searchParams.createTime"
          @on-change="d => searchParams.createTime=d"
        ></DatePicker>-->
			</CtmsSearchBoxItem>
		</CtmsSearchBox>

		<!--操作按钮-->
		<CtmsAction>
			<Button @click="() => this.$router.go(-1)">返回</Button>
		</CtmsAction>

		<!--主内容-->
		<CtmsDataGrid
			:height="dataGridHeight"
			:columns="columns"
			:data="listData"
			:page="page"
		></CtmsDataGrid>
	</div>
</template>

<script>
import api from "@/api/pharmacy/index"

import { mapState } from "vuex"

const { apigetDetailPage } = api

export default {
	name: "pharmacyDetail",
	data() {
		return {
			listData: [],
			columns: [
				{ title: "物品名称", key: "viewName", minWidth: 100 },
				{ title: "当前库存", key: "currAmount", minWidth: 150 },
				{ title: "总库存", key: "totalAmount", minWidth: 120 },
				{
					title: "类型",
					key: "type",
					minWidth: 120,
					render: (h, params) => h("span", params.row.type ? "耗材" : "药物")
				},
				{
					title: "操作",
					key: "action",
					width: 100,
					renderButton: params => {
						const actionList = [
							{
								label: "查看详情",
								on: {
									click: () => {
										let name = "SpecialPharmacyListDetailDrugInventory"
										// 药物
										if (params.row.type === 1) {
											name = "SpecialPharmacyListDetailMeterialInventory"
										}
										this.$router.push({
											name,
											query: {
												type: "pharmacy",
												projectId: params.row.projectId,
												drugId: params.row.id
											}
										})
									}
								}
							}
						]
						return actionList.filter(item => !!item)
					}
				}
			],

			loading: false,
			page: {
				current: 1,
				pageSize: 10,
				on: {
					onChange: this.onChange,
					onPageSizeChange: this.onPageSizeChange
				},
				total: 0
			},
			searchParams: {
				name: ""
			}
		}
	},
	created() {
		this.initList()
	},
	watch: {
		$route() {
			if (this.$route.query.pharmacyId) {
				this.initList()
			}
		}
	},
	computed: {
		...mapState({
			dataGridHeight: state => state.contentHeight - 130
		}),
		...mapState("permission", ["systemActionPermissions"])
	},
	methods: {
		initList() {
			this.$asyncDo(async () => {
				this.loading = true
				const res = await apigetDetailPage({
					id: this.$route.query.pharmacyId,
					pageNum: this.page.current,
					pageSize: this.page.pageSize,
					...this.oldSearchParams
				})
				if (res) {
					this.listData = res.data.list
					this.page.total = res.data.total
				}
				this.loading = false
			})
		},

		// 页面改变
		onChange(current) {
			this.page.current = current
			this.$nextTick(() => {
				this.initList()
			})
		},
		// 页面显示数量改变
		onPageSizeChange(pageSize) {
			this.page.pageSize = pageSize
			this.$nextTick(() => {
				this.initList()
			})
		},
		// 重置
		reset() {
			this.searchParams = {
				name: ""
			}
			this.searchHandle()
		},
		searchHandle() {
			this.oldSearchParams = Object.assign({}, this.searchParams)
			this.page.current = 1
			this.$nextTick(() => {
				this.initList()
			})
		}
	}
}
</script>
